import {makeStyles} from '@material-ui/core'
import {graphql} from 'gatsby'
import React from 'react'
import {Heading, PageContainer} from '../components'
import {PageLayout} from '../layout'
import {SiteMetadata} from '../types'

export interface PrivacyPolicyPageProps {
  data: {
    site: {siteMetadata: SiteMetadata}
  }
}

const useStyles = makeStyles({
  pageLayout: {
    height: '100% !important',
    alignItems: 'center !important',
  },
})

export const PrivacyPolicyPage: React.FC<PrivacyPolicyPageProps> = ({data}) => {
  const {site} = data

  if (!site || !site.siteMetadata) {
    throw new Error(
      `Site metadata doesn't exist. Please fix it before opening this page.`,
    )
  }
  const classes = useStyles()

  return (
    <PageLayout
      title="Privacy Policy"
      siteName={site.siteMetadata.title}
      description="Comprehensive privacy policy of our website. Here you can find information about how we treat your data."
      lang="en"
      className={classes.pageLayout}
    >
      <PageContainer>
        <Heading variant="h2" component="h1">
          Privacy Policy
        </Heading>

        <Heading variant="h3">Content coming soon.</Heading>
      </PageContainer>
    </PageLayout>
  )
}

export const query = graphql`
  query PrivacyPolicyPageQuery {
    site {
      siteMetadata {
        title
      }
    }
  }
`

export default PrivacyPolicyPage
